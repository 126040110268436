import people1 from '../../assest/taniska/india/Andaman/AndamantourImg.png'
import people2 from '../../assest/taniska/india/Delhi/Delhi2.jpg'
import people3 from '../../assest/taniska/india/Himachal/himachal_pradesh3.png'
import people4 from '../../assest/taniska/india/Jammukashmir/Jammukashmir5.jpg'
import people5 from '../../assest/taniska/india/kerala/kerala_4.jpg'
import people6 from '../../assest/taniska/india/goa/goa6.jpg'
import people7 from '../../assest/taniska/india/Rajasthan/RajasthanImgTour.jpg'
import people8 from '../../assest/taniska/india/Uttarakhand/Uttarakhand1.jpg'



const PopularToursIndiaData = [
    {
        id: 1,
        heading:'Andaman Tour Packages',
        textdetail:'The Andaman and Nicobar Islands is an extensive cluster of islands in…',
        linkTag: 'andaman',
        imgSrc: people1,
    },
    {
        id: 2,
        heading:'Delhi Tour Packages',
        textdetail:'The popular tour of the Golden Triangle includes the connectivity of…',
        linkTag: 'delhi',
        imgSrc: people2,
    },
    {
        id: 3,
        heading:'Himachal Pradesh Tour Packages',
        textdetail:'Nestled in the lap of the majestic Himalayas, Himachal Pradesh is a…',
        linkTag: 'himachal_pradesh',
        imgSrc: people3,
    } ,
     {
        id: 4,
        heading:'Jammu & Kashmir Tour Packages',
        textdetail:'With Jammu & Kashmir comes alive some terms synonymous to the…',
        linkTag: 'Jammu_kashmir',
        imgSrc: people4,
    },
     {
        id: 5,
        heading:'Kerala Tour Packages ',
        textdetail:'Are you dreaming of a vacation in paradise? Kerala, aptly called "God"…',
        linkTag: 'kerala',
        imgSrc: people5,
    }, {
        id: 6,
        heading:'Goa Tour Packages ',
        textdetail:'The tour to India is incomplete if North East is not brought into the…',
        linkTag: 'goa',
        imgSrc: people6,
    }
    , {
        id: 7,
        heading:'Rajasthan Tour Packages ',
        textdetail:'Rajasthan is located in North India with a keen historical significance…',
        linkTag: 'rajasthan',
        imgSrc: people7,
    }, {
        id: 8,
        heading:'Uttarakhand Tour Packages ',
        textdetail:'Uttarakhand is an area in Northern India which is',
        linkTag: 'uttarakhand',
        imgSrc: people8,
    }
   
];
export default PopularToursIndiaData