
import Footer from "../../common/TanishkaEnterprises/Footer/Footer"
import Header from "../../common/TanishkaEnterprises/Header/Header"
import polygon from '../../assest/taniska/usa/Vancouver.jpg'
import { Box, Button } from "@mui/material"
import { Link } from "react-router-dom"
import InternationalPackagesData from "../../data/tanishkaData/InternationalPackagesData"
import '../../styles/InternationalPackageStyle.css'

const InternationalPackages = () => {
    return (
        <>
            <Header />
            <Box className='hos_International_topdiv'>
                <img src={polygon} alt="img" />
                <div className="hosp_Internationalhead">
                    <h1>International Tour Packages</h1>
                </div>
            </Box>

            <div className="TravelOptions">
                <h1 className=" fw-bold py-4">International Tour Packages</h1>
                <div className="TravelOptions_slide">
                    {InternationalPackagesData?.map((item, index) => (
                        <Link to={`/international_packages/${item.linkTag}`}>
                            <div key={item.id} className="TravelOptions_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >
            <Footer />


        </>
    )
}

export default InternationalPackages