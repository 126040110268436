
import goa1 from '../../assest/taniska/india/goa/goa5.jpg'
import goa2 from '../../assest/taniska/india/goa/goa2.png'



export const DomesticGoaBannerData = [
    {
        bannerName: 'Goa',
        bannerImage: goa1,
    },
];

const DomesticGoaData = [
    {
        id: 1,
        tripName: 'Goa Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Goa, India',
        price: "₹20,671",
        imgSrc: goa2,
        linkTag:'domestic_packages/goa/goa_detail',
    }

];
export default DomesticGoaData