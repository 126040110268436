



import Uttarakhand1 from '../../assest/taniska/india/Uttarakhand/Uttarakhand1.jpg'
import Uttarakhand2 from '../../assest/taniska/india/Uttarakhand/Uttarakhand2.jpg'
import Uttarakhand4 from '../../assest/taniska/india/Uttarakhand/Uttarakhand4.jpg'





const DomesticUttarakhandDetailBannerData = [
    {
        id: 1,
        name: 'Uttarakhand Tour',
        src: Uttarakhand2,
    },
    {
        id: 2,
        name: 'Uttarakhand Tour',
        src: Uttarakhand1,
    },
    {
        id: 3,
        name: 'Uttarakhand Tour',
        src: Uttarakhand4,
    },
 
    
];
export default DomesticUttarakhandDetailBannerData


export const DomesticUttarakhandTextData = [
    {
      detailText: "Uttarakhand is a beautiful, religious state in the northern part of India that one can easily fall in love with. The surreal landscape that includes majestic mountains, glistening streams, imposing glaciers, eye-catching meadows, Uttarakhand culture, stunning bridges and pristine lakes; all make this state a top visiting tourist destination in India that not just satiates wanderlust but creates an urge to explore more and more and don't leave the place. Uttarakhand is a place to visit at least once in a lifetime, so don't miss the opportunity and grab huge discounts by booking Uttarakhand Tour Packages with us. Dotted with holy temples and trekking trails, it is one such destination where along with seeking the blessings of the almighty God, one can even indulge in breathtaking adventures and heart soothing recreational experiences. The major places that are very frequently visited by tourists from all over the world are Dehradun, Rishikesh, Roorkee, Rudrapur, Kashipur, Nainital, Gangotri, Kedarnath and Mussoorie. Uttarakhand culture revolves around traditional ethics, simplicity of nature, moral values and rich mythology. Uttarakhand culture includes various traditions, fairs, religions, festivals, folk dances and music; and this is what separates its people from the rest. One of the major festivals, Kumbh Mela takes places in Haridwar and is witnessed by thousands of people every year when the grand festival is being organized. A Uttarakhand tourism is definitely worth that not just provides an experience of witnessing the culture and top attractions of the state, but also changes our perspective on life. Book your Uttarakhand holiday packages to have an experience of a lifetime."
    }
];