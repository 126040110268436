

import people1 from '../../assest/taniska/indophiphi.jpg'
import indonesiaimg from '../../assest/taniska/indonesia.jpg'




export const InternationalIndonesiaBannerData = [
    {
        bannerName: 'Indonesia',
        bannerImage: indonesiaimg,
    },
];

const InternationalIndonesiaData = [
    {
        id: 1,
        tripName: 'Bali-Kuta 5N 6D',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Bali, Kuta',
        price: "₹20,671",
        imgSrc: people1,
        linkTag:'international_packages/indonesia/indonesia_detail',
    },
   

];
export default InternationalIndonesiaData