


import Thailandimg from '../../assest/taniska/Thailand/Thailand2.png'
import Thailandimg1 from '../../assest/taniska/Thailand/Thailand4.jpg'


export const InternationalThailandBannerData = [
    {
        bannerName: 'Thailand',
        bannerImage: Thailandimg,
    },
];

const InternationalThailandData = [
    {
        id: 1,
        tripName: 'Thailand Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Bangkok, Thailand',
        price: "₹20,671",
        imgSrc: Thailandimg1,
        linkTag:'international_packages/thailand/thailand_detail',
    },
   

];
export default InternationalThailandData