
import people1 from '../../assest/taniska/domestic4.webp'
import people2 from '../../assest/taniska/Andamanbeach.jpg'
import people3 from '../../assest/taniska/goabeach.jpg'
import people4 from '../../assest/taniska/fascinating-andaman.jpeg'
import people5 from '../../assest/taniska/the-best.jpeg'
import BeachImg from '../../assest/taniska/beachimg.jpg'




export const BeachBannerData = [
    {
        bannerName: 'Beach',
        bannerImage: BeachImg,
    },
];

const BeachData = [
    {
        id: 1,
        tripName: 'Bali-Kuta 5N 6D',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Bali, Kuta',
        price: "₹20,671",
        imgSrc: people1,
        linkTag:'fixed_departure/beach/beach_detail',
    },
    {
        id: 2,
        tripName: 'Exotic Andaman Holiday Package',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Port Blair, Havelock Island, Neil Island',
        price: "₹20,671",
        imgSrc: people2,
        linkTag:'fixed_departure/beach/beach_detail',
    },
    {
        id: 3,
        tripName: 'Amazing Goa',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Goa',
        price: "₹20,671",
        imgSrc: people3,

        linkTag:'fixed_departure/beach/beach_detail',    }, 
    {
        id: 4,
        tripName: 'Fascinating Andaman',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Port Blair, Havelock Island',
        price: "₹20,671",
        imgSrc: people4,
        linkTag:'fixed_departure/beach/beach_detail',
    },
    {
        id: 5,
        tripName: 'The Best of Thailand, Malaysia, and Singapore ',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: ' Pattaya, Bangkok, Kuala Lumpur, Singapore',
        price: "₹99,971",
        imgSrc: people5,
        linkTag:'fixed_departure/beach/beach_detail',
    },

];
export default BeachData