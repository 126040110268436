
import Andaman1 from '../../assest/taniska/india/Andaman/AndamantourImg.png'
import Andaman2 from '../../assest/taniska/india/Andaman/Andaman2.png'
import Andaman3 from '../../assest/taniska/india/Andaman/Andaman3.png'
import Andaman4 from '../../assest/taniska/india/Andaman/Andaman6.jpg'
import Andaman5 from '../../assest/taniska/india/Andaman/Andaman5.jpg'




const DomesticAndamanDetailBannerData = [
    {
        id: 1,
        name: 'Andaman and Nicobar Tour',
        src: Andaman3,
    },
    {
        id: 2,
        name: 'Andaman and Nicobar Tour',
        src: Andaman2,
    },
    {
        id: 3,
        name: 'Andaman and Nicobar Tour',
        src: Andaman4,
    },
    {
        id: 4,
        name: 'Andaman and Nicobar Tour',
        src: Andaman1,
    },
    {
        id: 5,
        name: 'Andaman and Nicobar Tour',
        src: Andaman5,
    },
   
    
];
export default DomesticAndamanDetailBannerData


export const DomesticAndamanTextData = [
    {
      detailText: "Want to witness the raw natural beauty of beaches surmounted by serene mountains and also dig through India’s oldest trade route and architecture? The Andaman and Nicobar Tour Package is the perfect destination to experience all of this. The Andaman & Nicobar islands consisting of more than 300 islands including rainfall island, Landfall Island, Interview Island, the Sentinel Islands, Ritchie’s Archipelago, and Rutland Island stretches for 1000 kilometres to form an arc between Myanmar and Indonesia makes an ideal getaway and your perfect long weekend destination. The Andaman & Nicobar Islands, a union territory formed in 1956, consists of Two Major Island groups separated by a 150 km Wide Channel. The Andaman Island known for its famous tourist spots, Havelock Island, is heaven for Scuba Divers and Snorkelling lovers. Other tourist attractions include the cellular jail, capital city of Port Blair, Radhanagar beach and so on. The Nicobar Island situated on the south of Andaman attracts tourists with its Famous spots like Campell Bay National Park, Galathea National Park, Mount Thuillier and the famous Indira Point, the southernmost point of Indian Subcontinent. The Andaman and Nicobar Tour Packages by Tanishka travels offers you a perfect weekend getaway to give you a lifetime of a trip to remember."
    }
];