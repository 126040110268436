import polygon from '../../assest/taniska/search-result-listing.jpg'
import { Box } from "@mui/material"
import '../../styles/contactUsStyle.css'
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'
import { MdEmail, MdOutlinePunchClock, MdLocalPhone } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import emailjs from "@emailjs/browser";
import { useRef, useState } from "react"
import Footer from "../../common/TanishkaEnterprises/Footer/Footer"
import Header from "../../common/TanishkaEnterprises/Header/Header"
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import ThankYouDialoge from '../../components/ThankYouDialoge'


const myMail = [
    {
        recipient: 'sales@tanishkatravels.com',
        subject: '',
        body: ''
    }
];
const ContactUs = () => {


    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };


    const [EnquiryData, SetEnquiryData] = useState({
        name: '',
        email: '',
        mobile: '',
        subject: '',
        message: '',
    })

    const [errorName, setErrorName] = useState("");
    const [errorEmail, setErrorEmail] = useState("");
    const [errorMobile, setErrorMobile] = useState("");
    const [bordrColor, setBordrColor] = useState("");

    const navigate = useNavigate()

    const onInputChnge = (e) => {
        const inpName = e.target.name;
        const inpVal = e.target.value;
        const cloned = { ...EnquiryData }
        cloned[inpName] = inpVal;
        SetEnquiryData(cloned);
    }


    const onSendClick = async (e) => {
        e.preventDefault();

        try {
            // Reset errors and color
            setErrorName("");
            setErrorEmail("");
            setErrorMobile("");
            setBordrColor("");

            // Perform input validation here before making the API call
            if (
                EnquiryData.name.length > 2 &&
                EnquiryData.email.includes("@") &&
                EnquiryData.mobile.length === 10 &&
                EnquiryData.subject.length > 2 &&
                EnquiryData.message.length > 2
            ) {
                setBordrColor('1px solid green');
                const sign_up = await axios.post(
                    'https://backoffice.adamclick.com/api/auth/lead/register',
                    { ...EnquiryData }
                );
                SetEnquiryData(sign_up.data);
                console.log(sign_up.data);

                // Clear the form fields
                SetEnquiryData({
                    name: '',
                    email: '',
                    mobile: '',
                    subject: '',
                    message: '',
                });

                setOpen(true);
                // Provide user feedback or navigation here if needed
                // navigate('/contact')
                console.log('Form data submitted successfully!');
            } else {
                if (EnquiryData.name.length <= 2 || EnquiryData.name === '') setErrorName("enter name");
                if (!EnquiryData.email.includes("@") || EnquiryData.email === '') setErrorEmail("enter email");
                if (EnquiryData.mobile.length !== 10 || EnquiryData.mobile === '') setErrorMobile("enter valid 10 digit number");

                setBordrColor('1px solid white');
                // Provide user feedback for validation errors
                console.log('Form validation failed');
            }
        } catch (error) {
            // Provide user-friendly error message
            console.log('Error submitting form:', error.message);
        }
    };

    const formRefrence = useRef();

    const sendToEmail = (e) => {
        //   e.preventDefault();

        //   emailjs
        //     .sendForm(
        //       "service_7rtuo0u",
        //       "template_0zel6ln",
        //       formRefrence.current,
        //       "5qBXGtbufOmEJ9GhA"
        //     )
        //     .then(
        //       (result) => {
        //         console.log(result.text);
        //       },
        //       (error) => {
        //         console.log(error.text);
        //       }
        //     );
        //   e.target.reset();
    };


    const defalutValue = {
        FullName: '',
        EmailID: '',
        Phone: '',
        YourMessage: '',

    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        FullName: yup.string().required('Name is Requird!'),
        EmailID: yup.string().required('Email is Requird!'),
        Phone: yup.string().required('Phone is Requird!'),
        YourMessage: yup.string().required('Your Message is Requird!'),

    })
    const handleSubmit = (value) => {
        console.log(value);

    }
    const dialNumber = () => {
        window.location.href = 'tel:+91-9871927829';
    };

    const emailClick = (recipient, subject, body) => {
        const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
    };
    return (
        <>
            <Header />

            <Box className='Contact_Hos_topdiv'>
                <img src={polygon} alt="img" />
                <div className="hosp_Contacthead">
                    <h1>Contact Us</h1>
                </div>
            </Box>

            <div className="p5tagdiv">
                <div className="agriLogistic_topdiv">
                    <h1 className=" fw-bold">Get In Touch</h1>
                </div>

                <div className=' row m-3'>
                    <div className=' col-12'>
                        <iframe
                            className="mapframe"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14012.486806235638!2d76.96874919433827!3d28.59612559175525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1021fd2223c1%3A0xe2a7582d715c4769!2sC%20Block%2C%205%2C%20Durga%20Vihar%20Rd%2C%20Deenpur%2C%20Durga%20Vihar%20I%2C%20Najafgarh%2C%20New%20Delhi%2C%20Delhi%2C%20110043!5e0!3m2!1sen!2sin!4v1728674178621!5m2!1sen!2sin"
                            width="600"
                            height="450"
                            style={{ border: 0 }}
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>

                <div className="row justify-content-between">

                    <div className="col-lg-6 col-12 card-body row ggggg">
                        <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                            {({ errors, touched, resetForm }) => {
                                return <Form className="row justify-content-center formdiv">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6 col-12 mt-2">
                                            <Field
                                                type='text'
                                                name='name'
                                                onChange={onInputChnge}
                                                value={EnquiryData.name}
                                                style={{ border: bordrColor, borderRadius: '4px' }}
                                                placeholder='Name'
                                                className={
                                                    `form-control
                                                  ${errors.name && touched.name ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}>{errorName}</p>
                                        </div>

                                        <div className=" col-lg-6 col-12 mt-2">

                                            <Field
                                                type='text'
                                                name='email'
                                                onChange={onInputChnge}
                                                value={EnquiryData.email}
                                                style={{ border: bordrColor, borderRadius: '4px' }}
                                                placeholder='Email'
                                                className={
                                                    `form-control
                                                   ${errors.email && touched.email ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}>{errorEmail}</p>
                                        </div>



                                        <div className="col-lg-6 col-12 mt-2">

                                            <Field
                                                type='text'
                                                name='mobile'
                                                onChange={onInputChnge}
                                                value={EnquiryData.mobile}
                                                style={{ border: bordrColor, borderRadius: '4px' }}
                                                placeholder='Mobile Number'
                                                className={
                                                    `form-control
                                                  ${errors.mobile && touched.mobile ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> {errorMobile}</p>
                                        </div>


                                        <div className="col-lg-6 col-12 mt-2">

                                            <Field
                                                type='text'
                                                name='subject'
                                                onChange={onInputChnge}
                                                value={EnquiryData.subject}
                                                style={{ border: bordrColor, borderRadius: '4px' }}
                                                placeholder='Subject'
                                                className={
                                                    `form-control
                                                       ${errors.subject && touched.subject ? ' is-invalid' : ''}`
                                                }
                                            />
                                        </div>



                                        <div className=" mt-2">

                                            <Field
                                                as={'textarea'}
                                                rows={6}
                                                name='message'
                                                onChange={onInputChnge}
                                                value={EnquiryData.message}
                                                placeholder='Your Message'
                                                className={
                                                    `form-control
                                                   ${errors.message && touched.message ? ' is-invalid' : ''}`
                                                }
                                            />
                                        </div>


                                        <div className="col-12 d-flex justify-content-center mt-2">
                                            <button onClick={onSendClick} type="submit" className="btnbtnbtn">
                                                Submit Form
                                            </button>

                                        </div>
                                    </div>

                                </Form>
                            }}
                        </Formik>
                    </div>

                    <div className=" col-lg-5 col-12 TouchContact ">
                        <div className="contactdiv">
                            <div className="ngo_icondiv">
                                <p> <IoLocationSharp className="icons" /></p>
                            </div>
                            <div className="ngocontact">
                                <p>
                                    <strong>Address : </strong>RZ-24, C Block, Gali no. 5, Durga Vihar Phase-2, Dindarpur Najafgarh, New Delhi-110043, India
                                </p>
                            </div>

                        </div>

                        <div className="contactdiv">
                            <div className="ngo_icondiv">
                                <p> <MdLocalPhone className="icons" /></p>
                            </div>
                            <div className="ngocontact">
                                <p onClick={dialNumber}> <strong>Phone : </strong>+91-9871927829</p>
                            </div>

                        </div>

                        <div className="contactdiv">
                            <div className="ngo_icondiv">
                                <p> <MdEmail className="icons" /></p>
                            </div>
                            <div className="ngocontact">
                                {myMail.map((email, index) => (
                                    <p key={index}
                                        onClick={() => emailClick(email.recipient, email.subject, email.body)}
                                    >
                                        <strong>Email ID : </strong> {email.recipient}
                                    </p>
                                ))}
                            </div>

                        </div>

                        {/* <div className="contactdiv">
                            <div className="ngo_icondiv">
                                <p> <MdOutlinePunchClock className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p> <strong>Office Hours : </strong> 10 AM To 6 PM Mon-Sat</p>
                            </div>

                        </div> */}
                    </div>

                </div>
            </div>
            <ThankYouDialoge open={open} setOpen={setOpen} handleClose={handleClose} />



            <Footer />


        </>
    )
}

export default ContactUs