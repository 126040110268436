

import kerala_1 from '../../assest/taniska/india/kerala/kerala_1.png'
import kerala_3 from '../../assest/taniska/india/kerala/kerala_3.png'
import kerala_4 from '../../assest/taniska/india/kerala/kerala_4.jpg'
import kerala_5 from '../../assest/taniska/india/kerala/kerala_5.jpg'





const DomesticKeralaDetailBannerData = [
    {
        id: 1,
        name: 'Kerala Tour',
        src: kerala_1,
    },
    {
        id: 2,
        name: 'Kerala Tour',
        src: kerala_3,
    },
    {
        id: 3,
        name: 'Kerala Tour',
        src: kerala_4,
    },
    {
        id: 4,
        name: 'Kerala Tour',
        src: kerala_5,
    },
   
    
];
export default DomesticKeralaDetailBannerData


export const DomesticKeralaTextData = [
    {
      detailText: "Kerala, also known as the 'God's own country', is a beautiful place in the south of India. Kerala is the twenty-third largest state in India in terms of total land area. Kerala's capital city is Thiruvananthapuram. Kerala is the land is spice gardens, land of coconuts and land of trees that is mind-blowing. Tourism, textiles, mining, fishery, gold production and handicrafts are some of the sectors that contribute greatly to the economic development of Kerala. Roads are well connected and villages are accessible because the places in Kerala are very well connected from the various modes of transportation. Education is given very high importance in this state and investments by the government in this sector is made to provide education to one and all. Most of the people speak Malayalam language followed by Tamil. The culture of Kerala is a very prominent one as it brings in the magic of traditional vibes. Kathakali dance, the ritual art of Theyyam, the Kerala meal Sadya, most significant festival that is Onam and other festivals and cultural performances from the base of traditional vibes in Kerala. The cuisine in Kerala includes a variety of popular food items such as idli, dosa, upma, fish curry, coconut chutney, egg masala, rice and rasam, banana chips, prawns and tasty sambhar. Book your Kerala tour packages today and explore the land of paradise and enjoy a relaxing and soulful vacation in Kerala. Home to beautiful backwaters, vast tea gardens, intelligent and literate citizens, tall palm trees, beautiful lakes, peak mountains, exciting hiking trails, colourful dance forms, long coastlines, majestic temples, cultural sites and museums are the highlights of Kerala. Kerala is considered as one of the best tropical tourist destinations in India. Kerala has also been given the title in the list of 'top ten paradises of the world' by National Geographic's Traveller Magazine in the year 2012. "
    }
];