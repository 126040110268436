import Header from "../common/TanishkaEnterprises/Header/Header"
import Footer from "../common/TanishkaEnterprises/Footer/Footer"
import polygon from '../assest/taniska/fixed.jpg'
import { Box, Button, Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { IoMdArrowDropdown } from "react-icons/io";
import TopLocationData from "../data/tanishkaData/TopLocationData";
import BalkansTourpackageData from "../data/tanishkaData/BalkansTourpackageData";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import '../styles/fixedDepartureStyle.css'

import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'
import CategoryData from "../data/tanishkaData/CategoryData";
// import { MultiSelect } from 'primereact/multiselect';
// import "primereact/resources/themes/lara-light-cyan/theme.css";
import { GiAnticlockwiseRotation } from "react-icons/gi";
import hotelimg from '../assest/taniska/hotel.png'
import logimg from '../assest/taniska/image.png'
import busimg from '../assest/taniska/bus.png'
import eatimg from '../assest/taniska/eat.png'
import emailjs from "@emailjs/browser";
import { useRef } from "react"
import { MdWhatsapp } from "react-icons/md";
import { FaArrowRightLong } from "react-icons/fa6";
import { Tab, TabPanel, Tabs, TabsList } from "@mui/base";
import axios from 'axios'
import ThankYouDialoge from "./ThankYouDialoge";



const cities = [
    { name: 'New York', code: 'NY' },
    { name: 'Rome', code: 'RM' },
    { name: 'London', code: 'LDN' },
    { name: 'Istanbul', code: 'IST' },
    { name: 'Paris', code: 'PRS' }
];

const LocationDetailPage = ({ BookingData, bookingBannerData }) => {

    console.log("bookingBannerData", bookingBannerData);
    const { bannerName, bannerImage } = bookingBannerData[0];
    console.log("bannerName", bannerName)
    console.log("bannerImage", bannerImage)

    const [selectedCities, setSelectedCities] = useState(null);
    const naviagte = useNavigate();

    const formRef = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_7rtuo0u",
                "template_0zel6ln",
                formRef.current,
                "5qBXGtbufOmEJ9GhA"
            )
            .then(
                (result) => {
                    console.log(result.text);
                },
                (error) => {
                    console.log(error.text);
                }
            );
        e.target.reset();
    };

    const defalutValue = {
        FullName: '',
        EmailID: '',
        Phone: '',
    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        FullName: yup.string().required('Full Name is Requird!'),
        EmailID: yup.string().required('Email is Requird!'),
        Phone: yup.string().required('Phone is Requird!'),
    })
    const handleSubmit = (value) => {
        console.log(value);

    }

    const toggleCategories = () => {
        var categoryList = document.getElementById('categoryList');
        categoryList.style.display = (categoryList.style.display === 'none') ? 'block' : 'none';
    }
    const toggleZone = () => {
        var zoneList = document.getElementById('zoneList');
        zoneList.style.display = (zoneList.style.display === 'none') ? 'block' : 'none';
    }
    const togglePrice = () => {
        var priceList = document.getElementById('priceList');
        priceList.style.display = (priceList.style.display === 'none') ? 'block' : 'none';
    }
    const toggleGender = () => {
        var genderList = document.getElementById('genderList');
        genderList.style.display = (genderList.style.display === 'none') ? 'block' : 'none';
    }
    const [showCount, setShowCount] = useState(12);
    const [showAll, setShowAll] = useState(false);

    const toggleImages = () => {
        if (!showAll) {
            const newShowCount = Math.min(showCount + 12, BalkansTourpackageData.length);
            setShowCount(newShowCount);
            if (newShowCount === BalkansTourpackageData.length) {
                setShowAll(true);
            }
        } else {
            setShowCount(showCount === 12 ? 3 : 12);
            setShowAll(false);
        }
    };

    // const viewDetailbtn = () => {
    //     naviagte(`/view_detail`)
    // }

    const handleContactClick = (phoneNumber) => {
        window.location.href = `whatsapp://send?phone=${encodeURIComponent(phoneNumber)}`;
    };


    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const [EnquiryData, SetEnquiryData] = useState({
        name: '',
        email: '',
        mobile: '',
        date: '',
        NoofPax: '',
        Destination: '',
        message: '',
    })

    const [errorName, setErrorName] = useState("");
    const [errorEmail, setErrorEmail] = useState("");
    const [errorMobile, setErrorMobile] = useState("");
    const [bordrColor, setBordrColor] = useState("");

    const navigate = useNavigate()

    const onInputChnge = (e) => {
        const inpName = e.target.name;
        const inpVal = e.target.value;
        const cloned = { ...EnquiryData }
        cloned[inpName] = inpVal;
        SetEnquiryData(cloned);
    }


    const onSendClick = async (e) => {
        e.preventDefault();

        try {
            // Reset errors and color
            setErrorName("");
            setErrorEmail("");
            setErrorMobile("");
            setBordrColor("");

            // Perform input validation here before making the API call
            if (
                EnquiryData.name.length > 2 &&
                EnquiryData.email.includes("@") &&
                EnquiryData.mobile.length === 10 &&
                !isNaN(new Date(EnquiryData.date).getTime()) &&
                EnquiryData.NoofPax.length > 0 &&
                EnquiryData.Destination.length > 2 &&
                EnquiryData.message.length > 2
            ) {
                setBordrColor('1px solid green');
                const sign_up = await axios.post(
                    'https://backoffice.adamclick.com/api/auth/lead/register',
                    { ...EnquiryData }
                );
                SetEnquiryData(sign_up.data);
                console.log(sign_up.data);

                // Clear the form fields
                SetEnquiryData({
                    name: '',
                    email: '',
                    mobile: '',
                    date: '',
                    NoofPax: '',
                    Destination: '',
                    message: '',
                });

                setOpen(true);
                // Provide user feedback or navigation here if needed
                // navigate('/contact')
                console.log('Form data submitted successfully!');
            } else {
                if (EnquiryData.name.length <= 2 || EnquiryData.name === '') setErrorName("enter name");
                if (!EnquiryData.email.includes("@") || EnquiryData.email === '') setErrorEmail("enter email");
                if (EnquiryData.mobile.length !== 10 || EnquiryData.mobile === '') setErrorMobile("enter valid 10 digit number");

                setBordrColor('1px solid white');
                // Provide user feedback for validation errors
                console.log('Form validation failed');
            }
        } catch (error) {
            // Provide user-friendly error message
            console.log('Error submitting form:', error.message);
        }
    };

    return (
        <>
            <Header />


            <Box className='Hosp_locationdetail_topdiv'>
                <img src={bannerImage} alt="img" />
                <div className="hosp_locationdetailhead">
                    <h1>{bannerName}</h1>
                </div>
            </Box>

            <Box>

                <div className="celebrity-filtration-div">
                    {/* <div className="leftsidediv col-sm-3">
                        <div className="filterdiv">
                            <h5>Filter</h5>
                            <h6><GiAnticlockwiseRotation />Reset</h6>
                        </div>

                        <div className="city-div">
                            <div onClick={toggleCategories} className="clickdiv">
                                <h6>Number of Nights</h6>
                                <IoMdArrowDropdown />
                            </div>
                            <ul id="categoryList" className="category-list">
                                <li >5 Days | 4 Nights</li>
                                <li >4 Days | 3 Nights</li>
                                <li >3 Days | 2 Nights</li>
                                <li >7 Days | 6 Nights</li>
                                <li >12 Days | 11 Nights</li>
                            </ul>
                        </div>

                        <div className="city-div">
                            <div onClick={toggleZone} className="clickdiv">
                                <h6>Category</h6>
                                <IoMdArrowDropdown />
                            </div>
                            <ul id="zoneList" className="zone-list">
                                {CategoryData?.map((div) => (
                                    <li key={div}>{div.name}</li>
                                ))}
                            </ul>
                        </div>


                        <div className="city-div">
                            <div onClick={togglePrice} className="clickdiv">
                                <h6>Destination</h6>
                                <IoMdArrowDropdown />
                            </div>
                            <ul id="priceList" className="price-list">
                                <li >Dubai</li>
                                <li >Goa</li>
                                <li >Srinagar</li>
                                <li >Port Blair</li>
                                <li >Havelock Island</li>
                                <li >Manali</li>
                                <li >Shimla</li>
                                <li >Pahalgam</li>
                                <li >Gulmarg</li>
                                <li >Chandigarh</li>
                                <li >Paris</li>
                                <li >Pattaya</li>
                                <li >Bangkok</li>
                                <li >Kuala Lumpur</li>
                                <li >Singapore</li>
                            </ul>
                        </div>



                        <div className="city-div">
                            <div onClick={toggleGender} className="clickdiv">
                                <h6>Price</h6>
                                <IoMdArrowDropdown />
                            </div>
                            <ul id="genderList" className="gender-list">
                                <li >Rs 10001-15000</li>
                                <li >Rs 15001-20000</li>
                                <li >Rs 20001-25000</li>
                                <li >Rs 25001-30000</li>
                                <li >Rs 95001-100000</li>
                            </ul>
                        </div>

                    </div> */}
                    <div className="rightsidediv col-sm-9">
                        {BookingData?.map((item, index) => (

                            <div className="fixedDeparture_package">
                                <div className=" row">
                                    <div className=" col-lg-4 col-12">
                                        <img src={item.imgSrc} alt="img" />

                                    </div>
                                    <div className=" col-lg-8 col-12">
                                        <div className="detail_full">
                                            <div className="leftdivname">
                                                <h3>{item.tripName}</h3>
                                                <h5>{item.dayNightPack}</h5>
                                                <h4>{item.Destinations}</h4>

                                            </div>
                                            <div className="rightdivname">
                                                {/* <p>{'Departure : Mumbai'}</p>
                                                      <p>{'Category : Deluxe'}</p> */}
                                                <h2><span>Starting from</span> <br /> {item.price}</h2>
                                                <p>Per Person</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="butndivbox">
                                    <Button className="btnn1"><Link to={`/${item.linkTag}`}> View Details <FaArrowRightLong className="ms-1 fs-6" /></Link></Button>
                                    <Button onClick={() => handleContactClick('9871927829')} className="btnn2"><MdWhatsapp className="me-2 fs-4" />Books Now</Button>
                                </div>

                                <div className="boxbottom">
                                    <Tabs className="tabsdivbox" defaultValue={5}>
                                        <TabsList>
                                            <Tab className="menutabs" value={0}>
                                                <img src={hotelimg} alt="img" />
                                                <p>Hotels</p>
                                            </Tab>
                                            <Tab className="menutabs" value={1}>
                                                <img src={logimg} alt="img" />
                                                <p>Sightseeing</p>
                                            </Tab>
                                            <Tab className="menutabs" value={2}>
                                                <img src={eatimg} alt="img" />
                                                <p>Meals</p>
                                            </Tab>
                                            {/* <Tab className="menutabs" value={3}>
                                                <img src={busimg} alt="img" />
                                                <p>Transfer</p>
                                            </Tab> */}
                                        </TabsList>

                                        <TabPanel className="tablePanelBox" value={0}>
                                            <div className="card">
                                                <div className="table-responsive">
                                                    <div className="">
                                                        <div className="card-body">
                                                            <div className="table-responsive text-nowrap">
                                                                <Table className="table">
                                                                    <TableHead component={'thead'} className="border-top">
                                                                        <TableRow component={'tr'}>
                                                                            <TableCell component={'th'} className=''>Location</TableCell>
                                                                            <TableCell component={'th'} className=''>Hotel Name</TableCell>
                                                                            <TableCell component={'th'} className=''>Room Category</TableCell>
                                                                            <TableCell component={'th'} className=''>Meal Plan</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody component={'tbody'}>

                                                                        {/* {FinanceRevenueData.map((item) => ( */}
                                                                        <>
                                                                            <TableRow component={'tr'}>
                                                                                <TableCell component={'td'}>{"Dubai (5 Nights)"}</TableCell>
                                                                                <TableCell component={'td'}>{"Omega Hotel"}</TableCell>
                                                                                <TableCell component={'td'}>{"Standard"}</TableCell>
                                                                                <TableCell component={'td'}>{"Breakfast (CP)"}</TableCell>
                                                                            </TableRow>
                                                                        </>
                                                                        {/* ))} */}
                                                                    </TableBody>
                                                                </Table>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </TabPanel>
                                        <TabPanel className="tablePanelBox" value={1}>
                                            <div className="card">
                                                <div className="table-responsive">
                                                    <div className="">
                                                        <div className="card-body">
                                                            <div className="table-responsive text-nowrap">
                                                                <Table className="table">
                                                                    <TableHead component={'thead'} className="border-top">
                                                                        <TableRow component={'tr'}>
                                                                            <TableCell component={'th'} className=''>Days</TableCell>
                                                                            <TableCell component={'th'} className=''>Description</TableCell>
                                                                        </TableRow>

                                                                    </TableHead>
                                                                    <TableBody component={'tbody'}>

                                                                        {/* {FinanceRevenueData.map((item) => ( */}
                                                                        <>
                                                                            <TableRow component={'tr'}>
                                                                                <TableCell component={'td'}>{"Day 01"}</TableCell>
                                                                                <TableCell component={'td'}>{"Dhow Cruise"}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow component={'tr'}>
                                                                                <TableCell component={'td'}>{"Day 02"}</TableCell>
                                                                                <TableCell component={'td'}>{"Burj Khalifa 124th Level (Non Prime Time) with Transfers , Half Day City tour"}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow component={'tr'}>
                                                                                <TableCell component={'td'}>{"Day 03"}</TableCell>
                                                                                <TableCell component={'td'}>{"Desert Safari Dubai"}</TableCell>
                                                                            </TableRow>

                                                                        </>
                                                                        {/* ))} */}
                                                                    </TableBody>
                                                                </Table>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </TabPanel>
                                        <TabPanel className="tablePanelBox" value={2}>
                                            <div className="card">
                                                <div className="table-responsive">
                                                    <div className="">
                                                        <div className="card-body">
                                                            <div className="table-responsive text-nowrap">
                                                                <Table className="table">
                                                                    <TableHead component={'thead'} className="border-top">
                                                                        <TableRow component={'tr'}>
                                                                            <TableCell component={'th'} className=''>Days</TableCell>
                                                                            <TableCell component={'th'} className=''>Description</TableCell>
                                                                        </TableRow>

                                                                    </TableHead>
                                                                    <TableBody component={'tbody'}>

                                                                        {/* {FinanceRevenueData.map((item) => ( */}
                                                                        <>
                                                                            <TableRow component={'tr'}>
                                                                                <TableCell component={'td'}>{"Day 01"}</TableCell>
                                                                                <TableCell component={'td'}>{"Lunch,Dinner"}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow component={'tr'}>
                                                                                <TableCell component={'td'}>{"Day 02"}</TableCell>
                                                                                <TableCell component={'td'}>{"Breakfast,Lunch,Dinner"}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow component={'tr'}>
                                                                                <TableCell component={'td'}>{"Day 03"}</TableCell>
                                                                                <TableCell component={'td'}>{"Breakfast,Lunch,Dinner"}</TableCell>
                                                                            </TableRow>

                                                                            <TableRow component={'tr'}>
                                                                                <TableCell component={'td'}>{"Day 04"}</TableCell>
                                                                                <TableCell component={'td'}>{"Breakfast,Lunch,Dinner"}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow component={'tr'}>
                                                                                <TableCell component={'td'}>{"Day 05"}</TableCell>
                                                                                <TableCell component={'td'}>{"Breakfast,Lunch,Dinner"}</TableCell>
                                                                            </TableRow>
                                                                            <TableRow component={'tr'}>
                                                                                <TableCell component={'td'}>{"Day 06"}</TableCell>
                                                                                <TableCell component={'td'}>{"Breakfast"}</TableCell>
                                                                            </TableRow>

                                                                        </>
                                                                        {/* ))} */}
                                                                    </TableBody>
                                                                </Table>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </TabPanel>
                                        {/* <TabPanel className="tablePanelBox" value={3}>
                                            3
                                        </TabPanel> */}
                                    </Tabs>

                                </div>
                            </div>
                        ))}

                        <div className="heretoserve">
                            <h1>We are here to serve you in the best way</h1>
                            <p>We would like to understand your plans and come up with the right strategic advice for best tour package for you.</p>
                        </div>
                        <div className="card-body row justify-content-center align-items-center me-0 mt-4">
                            <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row justify-content-center me-0 p-0">
                                {({ errors, touched, resetForm }) => {
                                    return <Form className="detailformdiv row justify-content-center align-items-center me-0">
                                        <div className="row me-0 justify-content-center align-items-center p-0">


                                            <div className="col-lg-6 col-12 mt-2">
                                                <Field
                                                    type='text'
                                                    name='name'
                                                    onChange={onInputChnge}
                                                    value={EnquiryData.name}
                                                    style={{ border: bordrColor, borderRadius: '4px' }}
                                                    placeholder='Name'
                                                    className={
                                                        `form-control
                                                  ${errors.name && touched.name ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}>{errorName}</p>
                                            </div>

                                            <div className=" col-lg-6 col-12 mt-2">

                                                <Field
                                                    type='text'
                                                    name='email'
                                                    onChange={onInputChnge}
                                                    value={EnquiryData.email}
                                                    style={{ border: bordrColor, borderRadius: '4px' }}
                                                    placeholder='Email'
                                                    className={
                                                        `form-control
                                                   ${errors.email && touched.email ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}>{errorEmail}</p>
                                            </div>



                                            <div className="col-lg-6 col-12 mt-2">

                                                <Field
                                                    type='text'
                                                    name='mobile'
                                                    onChange={onInputChnge}
                                                    value={EnquiryData.mobile}
                                                    style={{ border: bordrColor, borderRadius: '4px' }}
                                                    placeholder='Mobile Number'
                                                    className={
                                                        `form-control
                                                  ${errors.mobile && touched.mobile ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> {errorMobile}</p>
                                            </div>

                                            <div className=" col-lg-6 col-12 mt-2">

                                                <Field
                                                    type='date'
                                                    name='date'
                                                    onChange={onInputChnge}
                                                    value={EnquiryData.date}
                                                    placeholder='Travel Data'
                                                    className={
                                                        `form-control
                                                ${errors.date && touched.date ? ' is-invalid' : ''}`
                                                    }
                                                />
                                            </div>

                                            <div className=" col-lg-6 col-12 mt-2">

                                                <Field
                                                    type='text'
                                                    name='NoofPax'
                                                    onChange={onInputChnge}
                                                    value={EnquiryData.NoofPax}
                                                    placeholder='No.of Pax'
                                                    className={
                                                        `form-control
                                                ${errors.NoofPax && touched.NoofPax ? ' is-invalid' : ''}`
                                                    }
                                                />
                                            </div>

                                            <div className="col-lg-6  col-12 mt-2">

                                                <Field
                                                    type='text'
                                                    name='Destination'
                                                    onChange={onInputChnge}
                                                    value={EnquiryData.Destination}
                                                    placeholder='Destination'
                                                    className={
                                                        `form-control
                                                ${errors.Destination && touched.Destination ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="Destination" /></p>
                                            </div>

                                            <div className=" mt-2">

                                                <Field
                                                    as={'textarea'}
                                                    rows={6}
                                                    name='message'
                                                    placeholder='Remarks'
                                                    onChange={onInputChnge}
                                                    value={EnquiryData.message}
                                                    className={
                                                        `form-control
                                                     ${errors.message && touched.message ? ' is-invalid' : ''}`
                                                    }
                                                />
                                            </div>


                                            <div className="col-12 d-flex justify-content-end mt-3">
                                                <button onClick={onSendClick} type="submit" className="btnbtnbtn">
                                                    Send Enquiry
                                                </button>

                                            </div>
                                        </div>

                                    </Form>
                                }}
                            </Formik>
                        </div>
                    </div>

                </div>

            </Box>


            <ThankYouDialoge open={open} setOpen={setOpen} handleClose={handleClose} />

            <Footer />

        </>
    )
}

export default LocationDetailPage