
import maldivesImg from '../../assest/taniska/maldives.jpg'
import maldives1 from '../../assest/taniska/maldives/maldives4.jpg'


export const InternationalMaldivesBannerData = [
    {
        bannerName: 'Maldives',
        bannerImage: maldivesImg,
    },
];

const InternationalMaldivesData = [
    {
        id: 1,
        tripName: 'Maldives Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Maldives',
        price: "₹20,671",
        imgSrc: maldives1,
        linkTag:'international_packages/maldives/maldives_detail',
    },
   

];
export default InternationalMaldivesData