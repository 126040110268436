


import Punjab2 from '../../assest/taniska/india/Punjab/Punjab2.jpg'
import Punjab3 from '../../assest/taniska/india/Punjab/Punjab3.jpg'






const DomesticPunjabDetailBannerData = [
   
    {
        id: 1,
        name: 'Punjab Tour',
        src: Punjab2,
    },
    {
        id: 2,
        name: 'Punjab Tour',
        src: Punjab3,
    },
    
];
export default DomesticPunjabDetailBannerData


export const DomesticPunjabTextData = [
    {
      detailText: "Punjab, popularly known as 'The Land of Five Rivers', is located in the northwestern part of India. This state ranks as the most fertile land and most ancient civilization in the world. Punjab is popular for its religious diversity, the picturesque landscape, elegant antiquity and famous religious sites that are the most important reasons to visit Punjab. As it lures numerous tourists every year. You will feel part of the antique world when you travel in depth of Punjab. Even a fortnight of span is not enough to cover all the major places in Punjab."
    }
];