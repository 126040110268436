

import Punjab1 from '../../assest/taniska/india/Punjab/Punjab3.jpg'
import Punjab2 from '../../assest/taniska/india/Punjab/Punjab2.jpg'



export const DomesticPunjabBannerData = [
    {
        bannerName: 'Punjab',
        bannerImage: Punjab2,
    },
];

const DomesticPunjabData = [
    {
        id: 1,
        tripName: 'Punjab Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Punjab, India',
        price: "₹20,671",
        imgSrc: Punjab1,
        linkTag:'domestic_packages/punjab/punjab_detail',
    }

];
export default DomesticPunjabData