import people1 from '../../assest/taniska/blog1.webp'
import people2 from '../../assest/taniska/maldives/maldives4.jpg'
import people3 from '../../assest/taniska/Bhutan/Bhutan3.png'
import people4 from '../../assest/taniska/dubai3.jpg'
import people5 from '../../assest/taniska/kerala.jpg.webp'
import people6 from '../../assest/taniska/north-east-tour.jpg.webp'
import people7 from '../../assest/taniska/raasthan.jpg.webp'
import people8 from '../../assest/taniska/uttarakhand.jpg.webp'



const TravelogueData = [
    {
        id: 1,
        heading:'Maldives Unveiled: Tropical Paradise & Luxury Retreats',
        Postedby:"Anirban Bose",
        textdetail:'Welcome to the Maldives, where turquoise waters meet white sandy beaches, and',
        imgSrc: people1,
    },
    {
        id: 2,
        heading:'Maldives Unveiled: Tropical Paradise & Luxury Retreats',
        Postedby:"Anirban Bose",
        textdetail:'The popular tour of the Golden Triangle includes the connectivity of…',
        imgSrc: people2,
    },

    {
        id: 3,
        heading:'Maldives Unveiled: Tropical Paradise & Luxury Retreats ',
        Postedby:"Anirban Bose",
        textdetail:'Nestled in the lap of the majestic Himalayas, Himachal Pradesh is a…',
        imgSrc: people3,
    } ,
     {
        id: 4,
        heading:'Maldives Unveiled: Tropical Paradise & Luxury Retreats',
        Postedby:"Anirban Bose",
        textdetail:'With Jammu & Kashmir comes alive some terms synonymous to the…',
        imgSrc: people4,
    },
     {
        id: 5,
        heading:'Maldives Unveiled: Tropical Paradise & Luxury Retreats ',
        Postedby:"Anirban Bose",
        textdetail:'Are you dreaming of a vacation in paradise? Kerala, aptly called "God"…',
        imgSrc: people5,
    }, {
        id: 6,
        heading:'Maldives Unveiled: Tropical Paradise & Luxury Retreats ',
        Postedby:"Anirban Bose",
        textdetail:'The tour to India is incomplete if North East is not brought into the…',
        imgSrc: people6,
    }
    , {
        id: 7,
        heading:'Maldives Unveiled: Tropical Paradise & Luxury Retreats ',
        Postedby:"Anirban Bose",
        textdetail:'Rajasthan is located in North India with a keen historical significance…',
        imgSrc: people7,
    }, {
        id: 8,
        heading:'Maldives Unveiled: Tropical Paradise & Luxury Retreats ',
        Postedby:"Anirban Bose",
        textdetail:'Uttarakhand is an area in Northern India which is',
        imgSrc: people8,
    }
   
];
export default TravelogueData