import Vietnam1 from '../../assest/taniska/Vietnam/Vietnam1.png'
import VietnamTour from '../../assest/taniska/Vietnam/vietnam5.jpg'


export const InternationalVietnamBannerData = [
    {
        bannerName: 'Vietnam',
        bannerImage: Vietnam1,
    },
];

const InternationalVietnamData = [
    {
        id: 1,
        tripName: 'Vietnam Tour Packages',
        dayNightPack: '5 Days | 4 Nights',
        Destinations: 'Saigon, Vietnam',
        price: "₹20,671",
        imgSrc: VietnamTour,
        linkTag:'international_packages/vietnam/vietnam_detail',
    },
   

];
export default InternationalVietnamData