
import people6 from '../../assest/taniska/usa.webp'
import people7 from '../../assest/taniska/europe.webp'
import people8 from '../../assest/taniska/Nepal-Tour.jpg'
import people9 from '../../assest/taniska/Bhutan/Bhutan3.png'
import people10 from '../../assest/taniska/Sri-Lanka-tour.jpg'
import people11 from '../../assest/taniska/Vietnam/Vietnam1.png'
import people1 from '../../assest/taniska/burjkhalifa.jpg'
import people2 from '../../assest/taniska/malaysiya.webp'
import people3 from '../../assest/taniska/indophiphi.jpg'
import people4 from '../../assest/taniska/maldives.jpg'
import people5 from '../../assest/taniska/interantional7.webp'
import people12 from '../../assest/taniska/interantional4.webp'



const InternationalTourPackageData = [
    {
        id: 1,
        heading: 'Dubai Tour Packages',
        imgSrc: people1,
        linkTag: 'dubai',
    },
    {
        id: 2,
        heading: 'Malaysia Tour Packages',
        imgSrc: people2,
        linkTag: 'malaysia',
    },
    {
        id: 3,
        heading: 'Indonesia Tour Packages',
        imgSrc: people3,
        linkTag: 'indonesia',
    },
    {
        id: 4,
        heading: 'Maldives Tour Packages',
        imgSrc: people4,
        linkTag: 'maldives',
    },
    {
        id: 5,
        heading: 'Europe Tour Packages',
        imgSrc: people7,
        linkTag: 'europe',
    },
    {
        id: 6,
        heading: 'USA Tour Packages',
        imgSrc: people6,
        linkTag: 'USA',
    },
    {
        id: 7,
        heading: 'Sri Lanka Tour Packages',
        imgSrc: people10,
        linkTag: 'sri_lanka',
    },
    {
        id: 8,
        heading: 'Vietnam Tour Packages',
        imgSrc: people11,
        linkTag: 'vietnam',
    },
    {
        id: 9,
        heading: 'Nepal Tour Packages',
        imgSrc: people8,
        linkTag: 'nepal',
    },
    {
        id: 10,
        heading: 'Bhutan Tour Packages',
        imgSrc: people9,
        linkTag: 'bhutan',
    },
    {
        id: 11,
        heading: 'Singapore Tour Packages',
        linkTag: 'singapore',
        imgSrc: people12,
    },
    {
        id: 12,
        heading:'Thailand Tour Packages',
        linkTag:'thailand',
        imgSrc: people5,
    }, 


];
export default InternationalTourPackageData