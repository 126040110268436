


import Tamilnadu1 from '../../assest/taniska/india/Tamilnadu/Tamilnadu1.png'
import Tamilnadu2 from '../../assest/taniska/india/Tamilnadu/Tamilnadu2.png'
import Tamilnadu4 from '../../assest/taniska/india/Tamilnadu/Tamilnadu4.jpg'





const DomesticTamilNaduDetailBannerData = [
  
    {
        id: 1,
        name: 'Tamil Nadu Tour',
        src: Tamilnadu1,
    },
    {
        id: 2,
        name: 'Tamil Nadu Tour',
        src: Tamilnadu2,
    },
    {
        id: 3,
        name: 'Tamil Nadu Tour',
        src: Tamilnadu4,
    },
    
];
export default DomesticTamilNaduDetailBannerData


export const DomesticTamilNaduTextData = [
    {
      detailText: "The traditional culture and rich heritage passed down over the years which is not adulterated by the western influence are bound to give you a breathtaking experience once you visit the Southernmost state of India, Tamil Nadu. Tamil Nadu is bordered by the Bay of Bengal on the East, Kerala on the west, Andhra Pradesh and Karnataka on the North and North-West respectively. The Southernmost point of India, Kanyakumari is also the place where three oceans, Bay of Bengal, Indian Ocean and the Arabian Sea, meet and form a beautiful sight. The land of Tamil Nadu was ruled by the greatest emperors of the time such as Chera, Chola and Pandya who were greatly responsible for the cultural monuments and temples built with methods that the scientists of the modern world are still researching and has been a major study for the architecture field. The heritage left by the kings symbolises what TamilNadu is today. Tamil Nadu is the tenth-largest in India and the second-largest economy of India with a GDP of 230 billion USD. The language of Tamil was declared as a classical language by UNESCO and is also one of the oldest languages in the world according to recent findings. The State of Tamilnadu attracts a lot of tourists from around the world for its world-famous Dravidian architect temples, several beaches and also its rich delicacies of Tamil Food. Book your Tamilnadu tour package now from Sushant travels to avail attractive discounts and offer and have the best vacation Tamilnadu"
    }
];