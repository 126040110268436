
import banner2 from '../../assest/taniska/burjkhalifa2.jpg'
import banner1 from '../../assest/taniska/banner2.webp'
import banner3 from '../../assest/taniska/europe/hungary-454.jpg'
import banner4 from '../../assest/taniska/banner4.webp'
import banner5 from '../../assest/taniska/Thailand/Thailand2.png'
import banner6 from '../../assest/taniska/singalion.jpeg'
import banner7 from '../../assest/taniska/bali.webp'
import banner8 from '../../assest/taniska/abudhabi.webp'
import banner9 from '../../assest/taniska/malaysiya.webp'
import banner10 from '../../assest/taniska/banner1.webp'


const TopLocationData = [
    {
        id: 1,
        imgSrc: banner2,
        name:'Dubai'
    },
    {
        id: 2,
        imgSrc: banner3,
        name:'Europe'
    },
    {
        id: 3,
        imgSrc: banner4,
        name:'Maldives'

    }, {
        id: 4,
        imgSrc: banner5,
        name:'Thailand'
    }, {
        id: 5,
        imgSrc: banner1,
        name:'Sri Lanka'

    }
    , {
        id: 6,
        imgSrc: banner6,
        name:'Singapore'
    }
    , {
        id: 7,
        imgSrc: banner7,
        name:'Indonesia'
    }
    , {
        id: 8,
        imgSrc: banner8,
        name:'Abu Dhabi'
    }
    , {
        id: 9,
        imgSrc: banner9,
        name:'Malaysia'
    },
    {
        id: 10,
        imgSrc: banner10,
        name:'Vietnam'
    },
];
export default TopLocationData